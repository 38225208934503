@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import 'src/shared/scss/dt-base';

.content.content {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-auto-rows: 180px;
  grid-gap: 30px;
  justify-content: center;

  @include media($min-xs) {
    grid-template-columns: repeat(2, 292px);
  }

  @include media($min-sm) {
    grid-template-columns: repeat(2, 310px);
  }

  @include media($min-lg) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: rem(32) rem(40) 0 rem(40);
  }

  :global {
    .product-tile__container {
      background-color: $white;
      min-width: 100%;

      @include media($min-xs) {
        min-width: 292px;
      }

      @include media($min-sm) {
        min-width: 310px;
      }

      @include media($min-lg) {
        min-width: 250px;
        width: auto;
      }
    }

    .product-tile__details {
      width: 100%;
      flex-shrink: unset;
      padding-left: rem(20);

      @include media($min-lg) {
        padding-left: rem(30);
      }
    }

    .add-product-btn.add-product-btn {
      max-width: 145px;
      min-width: unset;
      font-weight: 700;
      font-size: rem(13);
    }
  }
}

@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base';

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid $dt-medium-light-gray;
  width: 255px;
  height: 190px;
}

.header {
  display: flex;
  align-items: center;
  height: 35px;
  margin: 0 15px;
  border-bottom: 1px solid $light-gray;
  font-size: rem(12);
  font-weight: 600;
  text-transform: uppercase;
}

.link {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  overflow: hidden;
  height: 100%;
}

.image {
  height: 100%;
  min-width: 92px;

  &:global(__image-block) {
    min-width: 92px;
    background-position-x: calc(100% - 10px);
  }
}

.details {
  width: 160px;
  height: 100%;
  padding: 21px 10px 14px 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brand-name {
  line-height: 1;
}

.brand {
  color: $black;
  text-transform: uppercase;
}

.code {
  color: $grey-700;
}

.name {
  color: $black;
  font-size: rem(18);
  line-height: rem(18);
}

.price-container {
  padding-top: 2px;
  min-height: 30%;
}

.price.price {
  font-size: rem(23);

  &:global(__from) {
    font-size: rem(12);
  }
}

.price-dash {
  font-weight: 800;
  color: $red-700;
}

.view-details {
  display: block;
  margin-top: 8px;
  font-size: rem(14);
}

.add-to-cart {
  padding: 10px;
  min-height: 40px;
  min-width: 140px;
}
